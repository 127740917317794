import './Desktop.css'
import React, { useContext, useEffect, useState,ref } from 'react'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle ,AiOutlineMenu,AiOutlineClose,} from 'react-icons/ai'
import { FaProjectDiagram } from "react-icons/fa";

// import {AiOutlineMenu} from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiLogOut } from 'react-icons/fi'
import { BsCartCheck ,BsCollection} from 'react-icons/bs'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Badge, Button, Dialog, DialogActions, DialogContent, Menu, MenuItem, Slide, Tooltip, Typography } from '@mui/material';
import { ContextFunction } from '../Context/Context';
import { toast } from 'react-toastify';
import { getCart, getWishList, handleLogOut, handleClickOpen, handleClose, Transition } from '../Constants/Constant'

const DesktopNavigation = (props) => {

  const { cart, setCart, wishlistData, setWishlistData } = useContext(ContextFunction)
  const [openAlert, setOpenAlert] = useState(false);
  const navigate = useNavigate()
  let authToken = localStorage.getItem('Authorization');
  let setProceed = authToken !== null ? true : false
  useEffect(() => {
    getCart(setProceed, setCart, authToken)
    getWishList(setProceed, setWishlistData, authToken)
  }, [])

  

  const [isMenuOpen, setIsMenuOpen] = useState(false)

const openMeun = ()=>{
  setIsMenuOpen(!isMenuOpen)
}

  const getDropDown = () =>{
    props.getDropDown()
  }

  // getDropDown
  return (
    <>
      <nav className='nav'>
        <div className="logo">
        <a href='/'>
        <img src="https://res.cloudinary.com/codepally/image/upload/v1669803366/UFUON%20LOGO%20PNG/ufuon%20logo%20playstore/uf2_iiwvue.png"/>
      </a>
    
         <div className="nav-Moblie">
      
            <ul className="nav-setup">
               
           
                {
                  isMenuOpen ?
                  <li>
                   
                  <a onClick={()=>openMeun()} ><AiOutlineClose style={{fontSize:'21px',color:'#000',position:'relative',left:'8%'}}/></a>
                
                  </li>
                  :
                  <li>
               
                    <a  onClick={()=>openMeun()}>  <AiOutlineMenu style={{fontSize:'21px',color:'#000',position:'relative',left:'8%'}}/> </a>
                 
                  </li>
                  
                }
                 
                 
              
            </ul>
            
        </div>
        {
          isMenuOpen ?
            <div class="menu">
                <div style={{position:'relative',bottom:'40px'}}>
                <a href='/'><div className='menu-sideNav'>Home </div></a>
                {
                  setProceed ?
                     <a href='/projects'><div className='menu-sideNav'>Project </div></a>
                     :
                     null
                }
                {
                  setProceed ?
                  <a  href='/list'><div className='menu-sideNav'>Project List </div></a>
                  :
                  null
                }
                {
                  setProceed ?
                     <a  href='/order'><div className='menu-sideNav'>Order </div></a>
                     :
                     null
                }
                  <a  href='https://www.ufuon.com/about' target={'_blank'}><div className='menu-sideNav'>About Company </div></a>
                  <a  href='https://www.ufuon.com/contact' target={'_blank'}><div className='menu-sideNav'>Contact </div></a>
                  <a  href='https://www.ufuon.com/terms' target={'_blank'}><div className='menu-sideNav'>Terms </div></a>
                </div>
            </div>
          :
          null
        }
    
        </div>

     
        <div className="nav-items">
          <ul className="nav-items">
            <li className="nav-links">
              <NavLink to='/'>
                <span className='nav-icon-span'>  Home</span>
              </NavLink>
            </li>
            {/* <li className="nav-links">
              <NavLink to='/contact'>
                <span className='nav-icon-span'>  Contact Us</span>
              </NavLink>
            </li> */}

            <li className="nav-links">
              <Tooltip title='Cart'>
                <NavLink to="/cart">
                  <span className='nav-icon-span'>Cart   <Badge badgeContent={setProceed ? cart.length : 0}> <AiOutlineShoppingCart className='nav-icon' /></Badge></span>
                </NavLink>
              </Tooltip>
            </li>
            <li className="nav-links">
              <Tooltip title='Wishlist'>
                <NavLink to="/wishlist">
                  <span className='nav-icon-span'>Wishlist  <Badge badgeContent={setProceed ? wishlistData.length : 0}> <AiOutlineHeart className='nav-icon' /></Badge></span>
                </NavLink>
              </Tooltip>
            </li>

            <li className="nav-links">
              <Tooltip title='Wishlist'>
                <NavLink to="/projects-list">
                  <span className='nav-icon-span'>Project  <Badge badgeContent={setProceed ? wishlistData.length : 0}> <FaProjectDiagram className='nav-icon' /></Badge></span>
                </NavLink>
              </Tooltip>
            </li>


            {
              setProceed ?
                <>
                  <li className="nav-links">
                    <a  onClick={()=>getDropDown()}>
                      <Tooltip title='Profile'>
                          <span className='nav-icon-span'>  <CgProfile style={{ fontSize: 29, marginTop: 7,marginRight:10 }} /></span>
                      </Tooltip>
                   </a> 
                    {
                    props.sendDropDown === true ?
                      <div className='nav-drop-body'>
                         <div id="dialog3" class="triangle_up1"></div>
                            <div className='nav-drop'>
                                  <Tooltip title='Collection'>
                                      <NavLink to='/projects'>
                                        <span>  <BsCollection style={{ fontSize: 20,marginRight:10 }} /> <span style={{position:'relative',bottom:'3px'}}>Projects</span></span>
                                      </NavLink>
                                    </Tooltip>

                                    <Tooltip title='List'>
                                      <NavLink to='/list'>
                                        <span>  <BsCollection style={{ fontSize: 20,marginRight:10 }} /> <span style={{position:'relative',bottom:'3px'}}>Project List</span></span>
                                      </NavLink>
                                    </Tooltip>

                                    <Tooltip title='Order'>
                                        <NavLink to='/order'>
                                          <span >  <BsCartCheck style={{ fontSize: 20,marginRight:10 }} /> <span style={{position:'relative',bottom:'3px'}}>Order</span></span>
                                        </NavLink>
                                    </Tooltip>


                                    <Tooltip title='Profile'>
                                      <NavLink to='/update'>
                                        <span >  <CgProfile style={{ fontSize: 20,marginRight:10 }} /> <span style={{position:'relative',bottom:'3px'}}>Profile</span></span>
                                      </NavLink>
                                    </Tooltip>

                                    <Tooltip title='Logout'>
                                      <a  onClick={() => handleClickOpen(setOpenAlert)}>
                                        <span >  <FiLogOut style={{ fontSize: 20, marginRight:10 }} /> <span style={{position:'relative',bottom:'3px'}}>Logout</span></span>
                                      </a>
                                    </Tooltip>
                            </div>
                         </div>
                      :
                          null
                    }
                    
                  </li>
                  
                 
                </>
                :
                <li className="nav-links">
                  <Tooltip title='Login'>
                    <NavLink to='/login'>
                      <span className='nav-icon-span'>   <CgProfile style={{ fontSize: 29, marginTop: 7 }} /></span>
                    </NavLink>
                  </Tooltip>
                </li>
            }
          </ul>


          
          
        
        </div>
      </nav >
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
          <Typography variant='h6'>  Do You Want To Logout?</Typography>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Link to="/">
            <Button variant='contained' endIcon={<FiLogOut />} color='primary' onClick={() => handleLogOut(setProceed, toast, navigate, setOpenAlert)}>Logout</Button></Link>
          <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={() => handleClose(setOpenAlert)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}

export default DesktopNavigation
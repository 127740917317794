
import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiLogOut,FiEdit } from 'react-icons/fi'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import axios from 'axios';
import { Link, NavLink, useNavigate ,useParams} from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,Tooltip, Grid, TextField,DialogContentText,
   Typography, InputLabel, MenuItem, FormControl, Select, CircularProgress} from '@mui/material';
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import { AiFillDelete,AiOutlineLogin} from 'react-icons/ai'
import TimeAgo from 'react-timeago'
// import { ContextFunction } from '../Context/Context';
import { toast } from 'react-toastify';
import './projects.css'
import './banner.css'
// import { getCart, getWishList, handleLogOut, handleClickOpen, handleClose, Transition } from '../Constants/Constant'

const Projects = (props) => {

    // const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [sectionsData, setSectionsData] = useState([])
    const [loading,setLoading] = useState(false);
    const [loadingData,setLoadingData] = useState(false);
    const [categories, setCategories] = useState([])
    const [navColor,setNavColor] = useState(false);
    const [projects , setProjects] = useState([])
    const [productInfo, setProductInfo] = useState({ name: ""});
       let authToken = localStorage.getItem('Authorization')
    const [openAlert, setOpenAlert] = useState(false);
    const [login,setLogin] = useState(false);
    const [deleteModal,setDeleteModal]= useState(false);
    const [link,setLink]= useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [getData,setGetData] = useState(null);
    const [changeUi,setChangeUi]= useState(false);
    let navigate = useNavigate()
    let setProceed = authToken ? true : false
    // let navigate = useNavigate()
    useEffect(() => {
      setLoading(true)
      getAllProduct()
        window.scroll(0, 0)
    }, [])


    const getAllProduct = async () => {
      // setLoading(false)
    //   console.log('-----------------4----seactions-----data',`${process.env.REACT_APP_SECTIONS}/public`)
      if (setProceed) {
          var { data } = await axios.get(`${process.env.REACT_APP_CATEGORY}`,
              {
                  headers: {
                      'Authorization': authToken
                  }
              })
              setCategories(data.data);
            //   console.log('-----------------4----projects-----data',data)
         var { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/public?limit=40`, {
                  headers: {
                      'Authorization': authToken
               }})
        // console.log('-----------------4----seactions-----data',process.env.REACT_APP_SECTIONS)
        setLoading(false)
        
        setProjects(data.data)
        // console.log('-----------------4----projects-----data',data)
      }else{
        setLogin(true)
      }

  }

  function ToSeoUrl(url) {
        
    // make the url lowercase         
    var encodedUrl = url.toString().toLowerCase(); 
  
    // replace & with and           
    encodedUrl = encodedUrl.split(/\&+/).join("-and-")
  
    // remove invalid characters 
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
  
    // remove duplicates 
    encodedUrl = encodedUrl.split(/-+/).join("-");
  
    // trim leading & trailing characters 
    encodedUrl = encodedUrl.trim('-'); 
  
    return encodedUrl; 
  }


  const OpneLink = async (data,index)=>{
    //  console.log('--data----OpneLink-------->',data)
            setLink(data)
            setChangeUi(true)
            setLoading(true)
            setNavColor(index)
            if (setProceed) {
             
            var { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/category/${data._id}`, {
                        headers: {
                            'Authorization': authToken
                    }})
            // console.log('-----------------4----seactions-----data',process.env.REACT_APP_SECTIONS)
            setLoading(false)
            setProjects(data.data)
            // console.log('-----------------4----projects-----data',data.data)
            }else{
            setLogin(true)
      }
  }


  // getDropDown
  return (
              <div>
                   <div>
                       <aside className="sidebar">
                                <ul className="sidebar-links">
                                    {
                                            categories.map((item, index) => (
                                                <li>
                                                    <a href="#" style={{color:index === navColor ?'#000':null}} onClick={()=>OpneLink(item,index)} className={index === navColor ?'sidebar-linksClick' : null} > <span className="material-symbols-outlined" style={{color:index === navColor ?'#000':null}}> move_up </span>{item.name}</a>
                                                </li>
                                            ))
                                    }
                                </ul>
                            </aside>
                       </div>
                     
                       <div className='project-body'>
                       {
                            changeUi === true ?
                               null
                              :
                                <div className="container banner-column">
                                <img className="banner-image" src="https://i.ibb.co/vB5LTFG/Headphone.png" alt="banner" />
                                <div className="banner-inner">
                                    <h1 className="heading-xl">INVEST IN STARTUPS  </h1>
                                    <p className="paragraph">
                                    There are loads of startups raising capital on StartEngine right now – in industries from biotech and robotics to film production and beyond.
                                    </p>
                                    <button className="btn btn-darken btn-inline">
                                        Our Products<i className="bx bx-right-arrow-alt"></i>
                                    </button>
                                </div>
                                <div className="banner-links">
                                    <a href="#" title=""><i className="fab fa-facebook"></i></a>
                                    <a href="#" title=""><i className="fab fa-instagram"></i></a>
                                    <a href="#" title=""><i className="bx bxl-twitter"></i></a>
                                    <a href="#" title=""><i className="bx bxl-youtube"></i></a>
                                </div>
                            </div>
                        }
                        {

                            loading === true?
                                 <div className='Homepage-loading'>
                                    <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                                </div>
                           :
                              <div className='project-list'>
                                    {
                                        projects.map((item, index) => (
                                            <div className="blog-card">
                                                <div className="meta">
                                                    <div className="photo" style={{backgroundImage: item.url === null || item.url === undefined? "url(https://storage.googleapis.com/chydlx/codepen/blog-cards/image-1.jpg)" :`url(${item.url})`}}></div>
                                                    <ul className="details">
                                                    <li className="author"><a href="#">{item.owner.firstname} {item.owner.lastname}</a></li>
                                                    <li className="date"> <TimeAgo  ago={false} date={ item.createdAt} /></li>
                                                    <li className="date">Products {item.products.length}</li>
                                                    <li className="tags">
                                                        <ul>
                                                        {
                                                            item.products.map((item, index) => (
                                                                <li><a>{ item.name.length > 13 ? item.name.slice(0, 13) + '...' : item.name} </a> </li>
                                                            ))
                                                         }
                                                        
                                                        </ul>
                                                    </li>
                                                    </ul>
                                                </div>
                                                <div className="description">
                                                    <h1>{ item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name}</h1>
                                                    {
                                                        item.category === undefined || item.category === null ?
                                                        <h2>Gruop project</h2>
                                                        :
                                                        <h2>{item.category.name}</h2>
                                                    }
                                                    {
                                                        item.title === undefined || item.title === null ?
                                                        <p> No title.</p>
                                                        :
                                                         <p> { item.title.length > 40 ? item.title.slice(0, 70) + '...' : item.title}.</p>
                                                    }
                                                    <p className="read-more">
                                                    <a href={`/project/detail/${ToSeoUrl(item.name)}/${item._id}`}>Read More</a>
                                                    </p>
                                                </div>
                                            </div>
                                            ))
                                        }            
                                 </div>
                              }
                        </div>
               </div>
       )
    }

export default Projects
import React ,{ useState , useEffect,useContext} from "react";
// import { truncateText } from "truncate-strings";
// import { useParams  } from "react-router-dom";
// import TimeAgo from 'react-timeago'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { CgProfile } from 'react-icons/cg'
import { FiLogOut,FiEdit } from 'react-icons/fi'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import axios from 'axios';
import { Link, NavLink, useNavigate ,useParams} from 'react-router-dom';
import { AiFillDelete} from 'react-icons/ai'
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider,Tooltip, Grid, TextField, Typography, Container,
  InputLabel, MenuItem, FormControl, Select, CircularProgress,DialogContentText,Skeleton,Rating
} from '@mui/material';
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
// import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
// import ProductCard from '../../../function/ProductCollectionCard'
// import {URL_EXAM_END, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';
import './Categories.css'
const Categoris =(props)=>{
    
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    const [source , setSource] = useState()
    const [categories, setCategories] = useState([])
    const [loading, setIsLoaded] = useState(false)
    const [loadingData,setLoadingData] = useState(false);
    const [editload, setEditload] = useState(false)
    const [error, setError] = useState()
    const [idArticle  ,setidArticle] = useState()
    const [edit  ,setEdit] = useState()
    const [value  ,setValue] = useState(null)
    const [modalCategory,setModalCategory] = useState(false)
    const  [modalCatalog, setModalCatalog]= useState(false)
    const [category, setCategory] = useState(null)
    const [modalDelete , setModalDelete] = useState(false)
    const [detail,setDetail] = useState(false)
    const [showCatalog,setShowCatalog]= useState(false)
    const [catalog,setCatalog]= useState(null)
    const [productInfo, setProductInfo] = useState({
        name: "",
         });

    const handleOnchange = (e) => {
        setProductInfo({ ...productInfo, [e.target.name]: e.target.value })
    }
  
    useEffect(() => {
        // setLoading(true)
        getAllProduct()
          window.scroll(0, 0)
      }, [])
  
  
      const getAllProduct = async () => {
        // setLoading(false)
        console.log('-----------------4----process.env.REACT_APP_CATEGORY_ADMIN-----data',process.env.REACT_APP_CATEGORY_ADMIN)
        if (setProceed) {
            const { data } = await axios.get(`${process.env.REACT_APP_CATEGORY_ADMIN}`,
                {
                    headers: {
                        'Authorization': authToken
                    }
                })
          console.log('-----------------4----seactions-----data',data)
        //   setLoading(false)
          setCategories(data.data);
         
        }else{
        //   setLogin(true)
        }
  
    }
  
  

//     useEffect(async () => {
//       setIsLoaded(true);
//         setidArticle(uuidv4())
//         // props.updateTitle('categories')
//         // props.error404(false)
//         const fetchData = async () => {
//             fetch("URL_EXAM_END"+"/categories")
//             .then(res => res.json())
//             .then(
//               (result) => {
//                   // console.log("data ------article----->",result)
//                 setIsLoaded(false);
//                 setCategories(result.data);
//               },
//               (error) => {
//                 setIsLoaded(false);
//                 setError(error);
//               }
//          )
//       }  
//         fetchData();
//    }, [])
 


const  addCateogory=()=>{
      console.log("loling to user")
  }

 const addModal = () =>{
    setModalCategory(!modalCategory)
 }

const EditShow=(data)=>{
    setCategory(data)
    setValue(data.name)
    // console.log("data=----------->",data)
    setEdit(true)
   
 }

 const CloseModal = ()=>{
    setCategory(null)
    setModalCategory(false)
    setModalCatalog(false)
    // setModalDelete
    // console.log("data=----------->",data)
    setEdit(false)
    setModalDelete(false)
    setDetail(false)
    setCatalog(null)
    setShowCatalog(false)
    setProductInfo({
        name:'',
       });
 }



const CreatData = async (e) => {
    e.preventDefault()
    setEditload(true)
    setLoadingData(true)
    try {
       
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
        }
        console.log("value=--------------------------->", process.env.REACT_APP_CATEGORY_ADMIN)
        
       
         const { data } = await axios.post(`${process.env.REACT_APP_CATEGORY_ADMIN}`,
                {
                    name: productInfo.name,
                    company :'store'
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            console.log("value=-------------4-------------->", process.env.REACT_APP_CATEGORY_ADMIN)
        // setOpenAlert(false);
        console.log('-----------------4---------data',data)
        if (data.status === true) {
        toast.success("Collection created", { autoClose: 500, theme: 'colored' })
        setLoadingData(false)
        
            setProductInfo({
                name: "",
            });
            CloseModal()
        }
        else {
        setLoadingData(false)
            toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
        }
      } catch (error) {
        setLoadingData(false)
          toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
      
}


const CreatCatalog = async (e) => {
    e.preventDefault()
    setEditload(true)
    setLoadingData(true)
    try {
       
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
        }
        console.log("value=--------------------------->", process.env.REACT_APP_CATALOG_ADMIN)
        
       
         const { data } = await axios.post(`${process.env.REACT_APP_CATALOG_ADMIN}`,
                {
                    name: productInfo.name,
                    company :'store',
                    category:category._id
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
        console.log('-----------------4---------data',data)
        if (data.status === true) {
            toast.success("Collection created", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
            
                setProductInfo({
                    name: "",
                });
                CloseModal()
            }
        else {
           setLoadingData(false)
            toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
        }
      } catch (error) {
        setLoadingData(false)
          toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
      
}

const UpdateCategory = async (e) =>{
    e.preventDefault()
    setLoadingData(true)
    // console.log('-----------------4---------data',productInfo.name)
    try {
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
        }
        else {
            const { data } = await axios.put(`${process.env.REACT_APP_CATEGORY_ADMIN}/${category._id}`,
                {
                    name: productInfo.name,
                    // company :'store'
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            // setOpenAlert(false);
            // console.log('-----------------4---------data',data)
            if (data.status === true) {
              toast.success("Category Updated", { autoClose: 500, theme: 'colored' })
                setProductInfo({
                    name: "",
                   });
               setLoadingData(false)
               CloseModal()
            //    setOpenEdit(false)
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
        }
       } catch (error) {
         setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }
  }

const  deleteCategory = async (e)=>{
    e.preventDefault()
    setLoadingData(true)
    // console.log('-----------------4---------data',getData)
       try  {
            const { data } = await axios.delete(`${process.env.REACT_APP_CATEGORY_ADMIN}/${category._id}`,
                 {
                headers: {
                    'Authorization': authToken
                }
            })
            // setOpenAlert(false);
            // console.log('-----------------4---------data',data)
            if (data.status === true) {
                CloseModal()
              toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
            //    setSectionsData(sectionsData.filter(item => item._id !== getData._id));
            //    setLoadingData(false)
            //    handleClose(false)
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
       } catch (error) {
         setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }
  }

  const deleteCatalog=  async (e)=>{
    e.preventDefault()
    setLoadingData(true)
    
    console.log('-----------------deleteCatalog4---------data',`${process.env.REACT_APP_CATALOG_ADMIN}/${catalog._id}`)
       try  {
            const { data } = await axios.delete(`${process.env.REACT_APP_CATALOG_ADMIN}/${catalog._id}`,
                 {
                headers: {
                    'Authorization': authToken
                }
            })
            // setOpenAlert(false);
            console.log('-----------------deleteCatalog4--------data',data)
            if (data.status === true) {
                CloseModal()
                setLoadingData(false)
                toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
                // setDetail(true)
                //    setSectionsData(sectionsData.filter(item => item._id !== getData._id));
                //    setLoadingData(false)
                //    handleClose(false)
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
       } catch (error) {
         setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }
  }


  const ShowDetail = (data)=>{
      setDetail(true)
      setCategory(data)
  }
  

const ShowMadalCatalog =(data)=>{
    setModalCatalog(!modalCatalog)
    setCategory(data)
}

const ShowMadalDelete = (data)=>{
    // setModalCatalog(!modalCatalog)
    setCategory(data)
    setModalDelete(true)
}

const ShowEditModal = (data)=>{
    setEdit(true)
    setCategory(data)
    setProductInfo({
        name: data.name,
       });
}

const HideCategory = (data)=>{
    setShowCatalog(true)
    setCatalog(data)

}

   const uuidv4=()=> {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }




  

   return(
       <div className="categoris">

           <div className='collections-title'>
                <button className="buttom-make collection-add" onClick={()=>setModalCategory(!modalCategory)}>
                    Add Categoris
                </button>
                    <h3>Categoris</h3>
            </div>
           
            <div className="category-list">
              {
                    loading === true?
                    <div className='Homepage-loading'>
                    <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                </div>
                :
                categories.map((item, index) => (
                
                <div className={'category-card'}>
                    <div className='adminSectionprice'>
                        <p >
                            <a href='#' onClick={()=>ShowMadalCatalog(item)} ><MdOutlineAdd style={{ fontSize: 27 }}/></a>
                            <a href='#'   href='#' onClick={()=>ShowEditModal(item)}> <FiEdit  style={{ fontSize: 20, marginTop: 1 }} /> </a>  
                            <a   href='#' onClick={()=>ShowMadalDelete(item)} > <AiFillDelete style={{ fontSize: 20 }} color='red'/> </a>  
                        </p>
                    </div> 
                            <h3 style={{marginLeft:'2%'}}><small> {item.name}</small></h3>
                            <h6 style={{marginLeft:'2%'}}><b>length</b>:  {  item.catalog.length  === 0 ? 0 :  item.catalog.length } </h6>
                              <a onClick={()=>ShowDetail(item)}>
                                {
                                  item.catalog.length  === 0 ?
                                   null
                                 :
                                 
                                   <div className='category-card-list'>
                                     {
                                                  item.catalog.map((item, index) => (
      
                                                  <div className='category-card-sub'>
                                                      <p>{item.name}</p>  
                                                  </div>
                                                  ))
                                          }
                                      </div>
                                  }
                            </a>
                        
                        </div>
                        ))
                       }
                 </div>   
          
                    <Dialog
                        open={modalCategory}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={CloseModal}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <form onSubmit={CreatData}>
                        <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                        <h4>Add Category</h4>
                        <br/>
                            <div style={{width:'95%'}}>
                                    
                                    
                                    <TextField label="Name" name='name'    value={productInfo.name}  onChange={handleOnchange} variant="outlined" fullWidth />
                                    
                            </div>
                            {
                                loadingData === true?
                                    <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                        <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                    </div>
                                :
                                <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button fullWidth variant='contained' type='reset' color='error' onClick={CloseModal} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Update</Button>
                                </DialogActions>
                            }
                        </DialogContent>
                        </form>
                </Dialog>


                    <Dialog
                    open={modalCatalog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={CloseModal}
                    aria-describedby="alert-dialog-slide-description">
                    <form onSubmit={CreatCatalog}>
                            <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                            <h4>Add catalog</h4>
                            <br/>
                                <div style={{width:'95%'}}>
                                        
                                    <TextField label="Name" name='name'    value={productInfo.name}  onChange={handleOnchange} variant="outlined" fullWidth />
                                        
                                </div>
                                {
                                    loadingData === true?
                                        <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                            <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                        </div>
                                    :
                                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                    <Button fullWidth variant='contained' type='reset' color='error' onClick={CloseModal} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                    <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Add</Button>
                                </DialogActions>
                                }
                            </DialogContent>
                            </form>
                    </Dialog>


                

                  <Dialog
                    open={edit}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={CloseModal}
                    aria-describedby="alert-dialog-slide-description"
                    >
                    {
                    category === null ?
                    null
                    :
                    <form onSubmit={UpdateCategory}>
                    <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                    <h4>Update Category</h4>
                        <br/>
                            <div style={{width:'95%'}}>
                                
                                
                                <TextField label="Name" name='name'    value={productInfo.name}  onChange={handleOnchange} variant="outlined" fullWidth />
                                
                            </div>
                            {
                            loadingData === true?
                                <div style={{marginTop:'15%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                        <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                </div>
                                :
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button fullWidth variant='contained' type='reset' color='error' onClick={CloseModal} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Update</Button>
                            </DialogActions>
                            }
                    </DialogContent>
                    </form>
                    }
                    
                </Dialog>


               <Dialog
                    open={modalDelete}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={CloseModal}
                    aria-describedby="alert-dialog-slide-description" >
                        <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                            <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                                <Typography variant='body1'>Do you want to delete this Project?</Typography>
                            </DialogContentText>
                            {
                            loadingData === true?
                                <div style={{alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',marginTop:'5%'}}>
                                    <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                </div>
                                :
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <Button variant='contained' endIcon={<AiFillDelete />} color='error' onClick={deleteCategory}>Delete</Button>
                                <Button variant='contained' color='primary'
                            onClick={() => CloseModal()} endIcon={<AiFillCloseCircle />}>Close</Button>
                            </DialogActions>
                            }
                        </DialogContent>
                    </Dialog>
 
                    <Dialog
                        open={detail}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={CloseModal}
                        aria-describedby="alert-dialog-slide-description" >
                        <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                            <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                            {
                                showCatalog === true ?
                                <Typography variant='body1'>Do you want to delete this {catalog.name}? from {category.name} </Typography>
                                :
                                null
                            }
                            </DialogContentText>
                              {
                                 category === null ||  category === undefined ?
                                  null 
                                :
                                 <div> 
                                   {
                                    
                                      showCatalog === true ?
                                          null
                                        :
                                     <div>
                                        <h3 style={{marginLeft:'2%'}}><small> {category.name}</small></h3>
                                        <h6 style={{marginLeft:'2%'}}><b>length</b>:  {  category.catalog.length  === 0 ? 0 :  category.catalog.length } </h6>
                                        <div style={{width:"90%",height:'10px',marginTop:'2%'}}>
                                        <hr/>
                                        </div>
                                     </div>
                                   }
                                   
                                     {
                                        showCatalog === true ?
                                          <div>
                                            {
                                                loadingData === true?
                                                    <div style={{alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',marginTop:'5%'}}>
                                                        <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                                    </div>
                                                    :
                                                <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    <Button variant='contained' endIcon={<AiFillDelete />} color='error' onClick={deleteCatalog}>Delete</Button>
                                                    <Button variant='contained' color='primary'
                                                onClick={() => CloseModal()} endIcon={<AiFillCloseCircle />}>Close</Button>
                                                </DialogActions>
                                                }
                                          </div>
                                          :
                                          <div>
                                          {
                                                    category.catalog.length  === 0 ?
                                                    null
                                                :
                                                
                                                    <div className='catalog-card-list'>
                                                    {
                                                        category.catalog.map((item, index) => (
                                                                    <a onClick={()=>HideCategory(item)}>
                                                                        <div className='category-card-sub'>
                                                                            <p>{item.name}</p>  
                                                                        </div>
                                                                    </a>
                                                                    ))
                                                            }
                                                        </div>
                                                    }
                                          </div>
                                     }
                                 </div>
                                 
                              }
                             


                         
                        </DialogContent>
                   </Dialog>
        
         </div>
   )
}


export default Categoris
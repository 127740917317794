import './App.css';
import React, { useContext, useEffect, useState,ref } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './Pages/Home/HomePage';
import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import Cart from './Pages/Cart/Cart';
import ProductDetail from './Pages/Detail/ProductDetail';
import SingleCategory from './SingleCategory/SingleCategory';
import MobileNavigation from './Navigation/MobileNavigation';
import DesktopNavigation from './Navigation/DesktopNavigation';
import Wishlist from './Pages/WhisList/Wishlist';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutForm from './Components/Checkout/CheckoutForm';
import UpdateDetails from './Pages/Update_User/UpdateDetails';
import ForgotPasswordForm from './Auth/ForgotPassword/ForgotPasswordForm';
import AddNewPassword from './Auth/ForgotPassword/AddNewPassword';
import AdminLogin from './Admin/Auth/Login/AdminLogin';
import AdminRegister from './Admin/Auth/Register/AdminRegister';
import AdminHomePage from './Admin/Pages/AdminHomePage';
import SingleUserPage from './Admin/Pages/SingleUserPage';
import SingleProduct from './Admin/Pages/SingleProduct';
import Collection from './Pages/Collection/Collection'
import CollectionList from './Pages/Collection/CollectionList'
import CollectionDetail from "./Pages/Collection/CollectionDetail";
import CollectionPublish from "./Pages/Collection/publiceCollection";
import AdminSection from './Admin/Pages/AdminSection/AdminSection';
import Order from './Pages/Order/Order'
import Projects from './Pages/project/projects';
import Categoris from './Pages/Categories/categories'
import Example from './Pages/example/example'



function App() {

  const [dropdown,setDropdown ] = useState(false)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

 

  const sendDropDown = (data) =>{
    console.log("data----------------->",data)
    setDropdown(data)
  }

  const getDropDown= () =>{
    console.log("data----------------->")
    setDropdown(!dropdown)
  }

  return (
    <>
      <ToastContainer toastClassName='toastContainerBox' transition={Flip} position='top-center' />
      <Router>
        <DesktopNavigation   sendDropDown={dropdown} getDropDown={getDropDown}  />
        <div className='margin'>
          <Routes>
            {/*User Routes  */}
            <Route path='/' index element={<HomePage  getDropDown={getDropDown}/>} />
            <Route path="/login" element={<Login getDropDown={getDropDown} />} />
            <Route path='/register' element={<Register  getDropDown={getDropDown}/>} />
            <Route path='/Detail/type/:cat/:name/:id' element={<ProductDetail  getDropDown={getDropDown}/>} />
            <Route path='/product/type/:cat' element={<SingleCategory getDropDown={getDropDown}/>} />
            <Route path='/cart' element={<Cart  getDropDown={getDropDown}/>} />
            <Route path='/wishlist' element={<Wishlist  getDropDown={getDropDown}/>} />
            <Route path='/projects' element={<Collection  getDropDown={getDropDown}/>} />
            <Route path='/list' element={<CollectionList  getDropDown={getDropDown}/>} />
            <Route path='/project/detail/:title/:id' element={<CollectionPublish  getDropDown={getDropDown}/>} />
            <Route path='/project/:id' element={<CollectionDetail  getDropDown={getDropDown}/>} />
            <Route path='/order' element={<Order  getDropDown={getDropDown}/>} />
            <Route path='/projects-list' element={<Projects  getDropDown={getDropDown}/>} />
            <Route path='/example' element={<Example  getDropDown={getDropDown}/>} />
            <Route path='/checkout' element={<CheckoutForm  getDropDown={getDropDown}/>} />
            <Route path='/update' element={<UpdateDetails  getDropDown={getDropDown}/>} />
            <Route path='/paymentsuccess' element={<PaymentSuccess getDropDown={getDropDown}/>} />
            <Route path='/forgotpassword' element={<ForgotPasswordForm  getDropDown={getDropDown}/>} />
            <Route path='/user/reset/:id/:token' element={<AddNewPassword />} />

            {/* Admin Routes */}
      
            <Route path="/admin/login" element={<AdminLogin  getDropDown={getDropDown}/>} />
            <Route path='/admin/register' element={<AdminRegister  getDropDown={getDropDown}/>} />
            <Route path='/admin/categoris' element={<Categoris  getDropDown={getDropDown}/>} />
            <Route path='/admin/home' element={<AdminHomePage  getDropDown={getDropDown}/>} />
            <Route path="/admin/section" element={<AdminSection  getDropDown={AdminSection}/>} />
            <Route path='/admin/home/user/:id' element={<SingleUserPage  getDropDown={getDropDown}/>} />
            <Route path='/admin/home/product/:type/:id' element={<SingleProduct  getDropDown={getDropDown}/>} />
          </Routes>
        </div>
        <MobileNavigation />
      </Router >


    </>
  );
}
export default App;
